// 定义分类相关的API接口函数
import request from '@/utils/request'

/**
 * 获取所有分类（顶级，二级，对应的产品推荐数据）
 * @returns Promise
 */
export const findAllCategory = () => {
  return request('/front/product/category/index?url=&appId=10002', 'get')
}

/**
 * 获取二级类目的筛选条件数据
 * @param {String} id - 二级类目ID
 * @returns
 */
export const findProductCategoryFilter = (id) => {
  return request('/front/product/category/index?url=&appId=10002', 'get', { id })
}

/**
 * 获取分类下的产品（带筛选条件）
 * @param {Object} params - 可参考接口文档
 */
export const findProductByCategory = (params) => {
  params.appId = 10002
  return request('/front/product/product/lists?url=&appId=10002', 'post', params);
}
